import { axiosInstance, createJWTToken } from "@src/api/axiosConfig";
import { setAccessTokenFunc, setPermissionFunc } from "@src/store/TokenStore";
import { Cookies } from "react-cookie";

export const cookie = new Cookies();
const devToken = process.env.NODE_ENV === "development" ? "accessToken" : "accessToken";

export function getToken() {
    return cookie.get(devToken);
}

export const mobileOS = /iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase());

type MapData = {
    geometryCoordinates: number[][];
    center: {
        lat: string;
        lng: string;
    };
    searchKeyword: string;
};

export const WebAppBridge = {
    setMobileToken: (token: string, userId: string) => {
        // 액세스 토큰 저장하기 (from android)
        const text = `[접속정보] : ${token} / ${userId}`;
        localStorage.setItem("accessToken", token);
        const tokenValue = localStorage.getItem("accessToken");
        axiosInstance.interceptors.request.use(
            (config: any) => {
                if (token) {
                    config.headers["Authorization"] = createJWTToken(tokenValue) || createJWTToken(token);
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            },
        );
    },
    setSearchKeyword: (keyword: string) => {
        // 임시 텍스트 저장하기 (from android)
        const text = keyword;
        localStorage.setItem("keyword", keyword);
    },
    setCadastralLayerFromApp: (value: boolean) => {
        return value;
    },
    hideBackToAppButtonInMap: () => {},
    setUserLocationInMap: (param1: string, param2: string) => {},
    setGrantedUserLocation: (b: boolean) => {
        setPermissionFunc(b.toString());
    },
    callAiListApi: () => {},
    setUserInfo: (token: string, userId: string, isSubscription: boolean, isDataMapLoadFirst: boolean, refreshToken: string) => {
        // 액세스 토큰 저장하기 (from android)
        const text = `[접속정보] : ${token} / ${userId}`;
        setAccessTokenFunc(token);
        localStorage.setItem("accessToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("isSubscription", isSubscription.toString());
        localStorage.setItem("isDataMapLoadFirst", isDataMapLoadFirst.toString());
        const tokenValue = localStorage.getItem("accessToken");
        axiosInstance.interceptors.request.use(
            (config: any) => {
                if (token) {
                    config.headers["Authorization"] = createJWTToken(tokenValue) || createJWTToken(token);
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            },
        );
    },
    checkCallMap: (check: boolean) => {},
    setSpeechResult: (speechResult: string) => {},
    showPushSettingInfo: () => {},
    isRoadView: () => {},
    setGrantedPhotoLibrary: (check: boolean) => {},
    filterDone: () => {},
};

declare global {
    interface Window {
        missgoauction: any;
        webkit: any;
    }
}

export const getDeviceType = () => {
    if (mobileOS) {
        // 유저에이전트를 불러와서 OS를 구분합니다.
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.search("android") > -1) return "android";
        else if (userAgent.search("iphone") > -1 || userAgent.search("ipod") > -1 || userAgent.search("ipad") > -1) return "ios";
    } else {
        // 모바일이 아닐 때
        return "모바일이 아님";
    }
};

export const transferToNative = (data: any, methodName: string) => {
    try {
        if (getDeviceType() === "android") {
            window.missgoauction[methodName](data) as any;
        }

        if (getDeviceType() === "ios") {
            window.webkit.messageHandlers[methodName].postMessage(data);
        }
    } catch (e) {
        console.log(`device transfer to native error - [${methodName}]`);
        console.log(data);
        console.log(e);
    }

    return "";
};
