import React, { useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import { axiosPath } from "@src/api/axiosPath";
import { Button } from "@src/components";
import { useNavigate } from "react-router-dom";
import { cookie, WebAppBridge } from "@src/lib/WebAppBridge";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import { useTokenStore } from "@src/store/TokenStore";
import SubscriptionSticky from "@src/pages/subscription/SubscriptionSticky";

function Login() {
    const navigate = useNavigate();
    const [clickSticky, setClickSticky] = useState(0);
    const onLogin = async () => {
        setWelcomeMsg("");
        localStorage.clear();
        await axios
            .post(`${axiosPath.login}`, {
                id: "t5@msga.msg",
                password: {
                    password: "missgo237",
                },
            })
            .then((res: any) => {
                let payload = res.data;
                const { accessToken, refreshToken } = payload;
                // WebAppBridge.setMobileToken(accessToken, "");
                WebAppBridge.setUserInfo(accessToken, "", true, true, refreshToken);
                localStorage.setItem("isSubscription", "true");
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                setWelcomeMsg("로그인 되었습니다.");
            })
            .catch((e) => alert(e));
    };
    const [welcomeMsg, setWelcomeMsg] = useState("");
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);

    return (
        <div css={loginWrap}>
            <div className="step1">
                {/* <h3>1. 우선 로그인을 해주세요.</h3> */}
                {welcomeMsg ? (
                    <span>{welcomeMsg}</span>
                ) : (
                    <>
                        <button className="loginBtn" onClick={onLogin}>
                            로그인하기
                        </button>
                    </>
                )}
                {welcomeMsg && (
                    <>
                        <h3>2. 상세 페이지로 이동하세요.</h3>
                        <ul>
                            <li>
                                <span>지도 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/map?search=0")}
                                    label="지도"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            {/* <li>
                                <span>지도 검색 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/map?search=1")}
                                    label="지도 검색"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li> */}
                            <li>
                                <span>지도 필터(네이티브연동) 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/newFilter")}
                                    label="앱지도 필터"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>아이템 리스트 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/items")}
                                    label="매물 리스트"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>AI 맞춤필터 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/aiFilterV2")}
                                    label="Ai 맞춤필터"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>소액 맞춤필터 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/smallFilter")}
                                    label="소액 맞춤필터"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>등기 변동 알림 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/deungi/alarm")}
                                    label="등기변동알림"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>등기부등본 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/deungi")}
                                    label="등기부등본"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>등기부등본 열람권 충전 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/deungi/payment")}
                                    label="등기부등본 열람권 충전 페이지"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>미스고 캠퍼스</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/campus")}
                                    label="미스고 캠퍼스"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>미스고 캠퍼스 취소/환불</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/campus/refund")}
                                    label="미스고 캠퍼스"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>거리뷰</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/panoramaView")}
                                    label="거리뷰 토큰"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/unSubscribe")}
                                    label="환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>전문가 문의 관리</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/expert/inquiry")}
                                    label="전문가 문의 관리"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>경매대행 지역광고 안내 팝업</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/expert/localAd/introduction/popup")}
                                    label="경매대행 지역광고 안내 팝업"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>광고 매물 관리</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/expertAdList")}
                                    label="광고 매물 관리"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>고스톱 유료페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/goStop")}
                                    label="고스톱 유료페이지"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>상가 고스톱 유료페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/storeGoStop")}
                                    label="상가 고스톱 유료페이지"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>광고권 취소/환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/advertisementCancelRefund")}
                                    label="광고권 취소/환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>지역광고권 취소/환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/regionAdsRefund")}
                                    label="지역광고권 취소/환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>건축물대장 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/buildingRegister")}
                                    label="건축물대장"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>국유지 이용권 취소/환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/stateLandRefund")}
                                    label="국유지 이용권 취소/환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>도로확장 계획 취소/환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/roadPlanRefund")}
                                    label="도로확장 계획 취소/환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>주거/토지 프리미엄 필터 취소/환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/goStopRefund")}
                                    label="주거/토지 프리미엄 필터 취소/환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>상가 Go-Stop 취소/환불하기</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/storeGoStopRefund")}
                                    label="상가 Go-Stop 취소/환불하기"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>구독 상품 및 설명</span>
                                <div css={doubleBtnBoxStyle}>
                                    <Button
                                        color="purple"
                                        handleClick={() => navigate("/subscriptionInfo?type=6")}
                                        label="AOS 상품"
                                        size="small"
                                        deleteBtn={true}
                                    />
                                    <Button
                                        color="purple"
                                        handleClick={() => navigate("/ios-subscriptionInfo?type=7")}
                                        label="IOS 상품"
                                        size="small"
                                        deleteBtn={true}
                                    />
                                </div>
                            </li>
                            <li>
                                <span>국유지 이용권 및 설명</span>
                                <div css={doubleBtnBoxStyle}>
                                    <Button
                                        color="purple"
                                        handleClick={() => navigate("/stateLandInfo?type=8")}
                                        label="AOS 상품"
                                        size="small"
                                        deleteBtn={true}
                                    />
                                    <Button
                                        color="purple"
                                        handleClick={() => navigate("/stateLandInfo?type=7")}
                                        label="IOS 상품"
                                        size="small"
                                        deleteBtn={true}
                                    />
                                </div>
                            </li>
                            <li>
                                <span>국공유지 필터 (네이티브) 연동페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/stateLandUserFilter")}
                                    label="국공유지 필터"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                            <li>
                                <span>Ios 데이터맵 이벤트 페이지</span>
                                <Button
                                    color="purple"
                                    handleClick={() => navigate("/introduction/IosDatamap")}
                                    label="Ios 데이터맵 이벤트 페이지"
                                    size="small"
                                    deleteBtn={true}
                                />
                            </li>
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
}

const loginWrap = css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: scroll;

    line-height: 1.5;
    text-align: center;
    .loginBtn {
        margin-top: 10px;
        padding: 5px;
        background-color: #ee5b7a;
        color: #fff;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
    }
    h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .step1 span {
        font-size: 12px;
    }
    ul button {
        margin: 10px auto 0;
    }
    li {
        margin-bottom: 20px;
    }
`;

const doubleBtnBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 10px;
    button {
        margin: 0 !important;
    }
`;
export default Login;
