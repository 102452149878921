import React from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import checkIcon from "@src/assets/ico_check_white.svg";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { getGoStopAuctionMasterItems } from "@src/constants/auctionMasterItemFilters";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

const GoStopAuctionMasterItemFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    const onClickMasterItem = (itemNumber: number) => {
        const isSelectAll = itemNumber === 0;
        if (isSelectAll) {
            setFilterParams({ ...filterParams, auction_master_item: [] });
            return;
        }

        const isSelectedItemInFilter = filterParams.auction_master_item?.some((val: number) => val === itemNumber);

        if (isSelectedItemInFilter) {
            const filteredItemList = [...filterParams.auction_master_item].filter((val) => val !== itemNumber);
            setFilterParams({
                ...filterParams,
                auction_master_item: filteredItemList,
            });
        } else {
            const isEqualSelectAll =
                filterParams.auction_master_item.length + 1 === Object.keys(getGoStopAuctionMasterItems()).length - 1;
            isEqualSelectAll
                ? setFilterParams({
                      ...filterParams,
                      auction_master_item: [],
                  })
                : setFilterParams({
                      ...filterParams,
                      auction_master_item: [...filterParams.auction_master_item, itemNumber],
                  });
        }
    };

    const isSelectedItem = (itemNumber: number) => {
        const isSelectAll = itemNumber === 0;
        return isSelectAll
            ? filterParams.auction_master_item.some((val: number) => !val) || !filterParams.auction_master_item.length
            : filterParams.auction_master_item?.some((val: number) => val == itemNumber);
    };
    return (
        <div css={rootBoxStyle}>
            <div
                css={titleBoxStyle(
                    filterParams.auction_master_item.some((val: any) => !val) || !filterParams.auction_master_item.length,
                )}
            >
                <p className="titleText">경매고수물건</p>
                {allFilter ? (
                    <button className="allButton" onClick={() => setFilterParams({ ...filterParams, auction_master_item: [] })}>
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={selectButtonBoxStyle}>
                {Object.entries(getGoStopAuctionMasterItems()).map(
                    ([title, itemNumber]) =>
                        (!allFilter || !!itemNumber) && (
                            <button
                                key={itemNumber}
                                css={selectButtonStyle(isSelectedItem(itemNumber))}
                                onClick={() => onClickMasterItem(itemNumber)}
                            >
                                {title}
                            </button>
                        ),
                )}
            </div>
            <div css={excludeBoxStyle}>
                <div
                    css={checkBoxRootStyle}
                    onClick={() =>
                        setFilterParams({ ...filterParams, auction_master_except: !filterParams.auction_master_except })
                    }
                >
                    <div css={checkBoxStyle(filterParams.auction_master_except)}>
                        <img src={checkIcon} />
                    </div>
                </div>
                <div>
                    <h3>제외하기</h3>
                    <div>
                        <span className="example">선택한 필터에 해당하는 매물 제외하고 매물보기</span>
                        <br />
                        <span className="ex">ex. 유치권 필터 선택 후 제외하기 체크시 유치권 매물을 제외하고 보여줍니다</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoStopAuctionMasterItemFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : "#ffffff"};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #f0f0f0;
    background-color: ${active ? Common.colors.aucMarkerColor : "#f0f0f0"};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;

const excludeBoxStyle = css`
    display: flex;
    padding: 14px;
    margin-top: 24px;
    h3 {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
    }

    .example {
        font-size: 13px;
        line-height: 20px;
        color: #505050;
    }

    .ex {
        font-size: 12px;
        line-height: 18px;
        color: #a3a3a3;
    }
`;

const checkBoxStyle = (active: boolean) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px !important;
    height: 20px !important;
    background: ${active ? Common.colors.aucMarkerColor : "#A3A3A3"};
    border-radius: 100%;
    margin-right: 14px;
    img {
        width: 10px;
    }
`;

const checkBoxRootStyle = css`
    width: 32px;
    padding-top: 4px;
`;
