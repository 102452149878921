import { css } from "@emotion/react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import { Common } from "@src/styles/Common";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { landUseCategoryListLength, useFilterList, useFilterTextList, useMajorCategory } from "../newFilter/useFilterList";
import ShowSelectedUseLocValueBox from "../newFilter/ShowSelectedUseLocValueBox";
import AiTitle from "@src/components/aiFilter/AiTitle";
import { MAX_Y } from "@src/components/aiFilter/Constants";

interface Props {
    categories: string[];
    onChangeCategories: (key: string, val: any) => void;
    showToast: () => void;
    onClickDone?: () => void;
}

const totalUseLength = 10;

const useMajorCategoryWithoutAll = useMajorCategory.filter((v) => v.name !== "전체");

const AiRecommendThirdStep: React.FC<Props> = ({ categories, onChangeCategories, showToast, onClickDone }) => {
    const [selectedMajor, setSelectedMajor] = useState<string>("");
    const majorRef = useRef<any>();
    const getRealLength = (val: string[]) => {
        const checkALength =
            val.filter((v) => v.includes("A")).length === landUseCategoryListLength["A"]
                ? 1
                : val.filter((v) => v.includes("A")).length;
        const checkBLength =
            val.filter((v) => v.includes("B")).length === landUseCategoryListLength["B"]
                ? 1
                : val.filter((v) => v.includes("B")).length;
        const checkCLength =
            val.filter((v) => v.includes("C")).length === landUseCategoryListLength["C"]
                ? 1
                : val.filter((v) => v.includes("C")).length;
        const checkDLength =
            val.filter((v) => v.includes("D")).length === landUseCategoryListLength["D"]
                ? 1
                : val.filter((v) => v.includes("D")).length;
        return checkALength + checkBLength + checkCLength + checkDLength > totalUseLength;
    };

    useEffect(() => {
        if (categories.length === 0) {
            const { inputValue, name } = useMajorCategoryWithoutAll[0];
            onChangeCategories("categories", [...inputValue]);
            setSelectedMajor(name);
        }

        if (categories.some((ele) => ele.split("").some((data) => data === "A"))) {
            setSelectedMajor("주거용");
        } else if (categories.some((ele) => ele.split("").some((data) => data === "B"))) {
            setSelectedMajor("토지");
        } else if (categories.some((ele) => ele.split("").some((data) => data === "C"))) {
            setSelectedMajor("산업용");
        } else if (categories.some((ele) => ele.split("").some((data) => data === "D"))) {
            setSelectedMajor("상업/업무용");
        } else if (categories.some((ele) => ele.split("").some((data) => data === "E"))) {
            setSelectedMajor("기타");
        } else return;
    }, []);

    return (
        <div css={rootBoxStyle}>
            <AiTitle>
                <h2>
                    관심있는 <span className="color-text">용도</span>를 선택해주세요.
                </h2>
            </AiTitle>
            <div css={summaryBoxStyle}>
                {!!categories.length &&
                    Array.from(
                        new Set(
                            categories.map((v: any) => {
                                const checkMajor = v.slice(0, 1);
                                const checkActiveList = categories.filter((val: any) => val.includes(checkMajor));
                                const activeListLengthCheck =
                                    checkMajor === "A"
                                        ? checkActiveList.length === landUseCategoryListLength["A"]
                                        : checkMajor === "B"
                                        ? checkActiveList.length === landUseCategoryListLength["B"]
                                        : checkMajor === "C"
                                        ? checkActiveList.length === landUseCategoryListLength["C"]
                                        : checkMajor === "D"
                                        ? checkActiveList.length === landUseCategoryListLength["D"]
                                        : null;
                                return activeListLengthCheck ? checkMajor : v;
                            }),
                        ),
                    ).map((val: any) => {
                        const newText = useFilterTextList.filter((text: any) => text.value === val);
                        return (
                            <ShowSelectedUseLocValueBox
                                value={newText?.[0]?.name}
                                onDeleteValue={() => {
                                    const newUse = [...categories].filter((val: any) => !val.includes(newText?.[0]?.value));
                                    onChangeCategories("categories", newUse);
                                }}
                                key={val}
                            />
                        );
                    })}
            </div>
            <div css={subTitleBoxStyle}>
                <p className="subTitleText">대분류</p>
                <p className="subTitleText">중분류</p>
            </div>
            <div css={selectBoxRootStyle}>
                <div css={selectBoxStyle(MAX_Y)} ref={majorRef}>
                    {useMajorCategoryWithoutAll.map((category, idx) => {
                        let checkActive: boolean = false;
                        if (categories.length) {
                            checkActive = categories.some((val: any) => val?.includes(category.value));
                        }
                        return (
                            <button
                                key={category.name}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    let newUse: string[] = [];
                                    if (checkActive) {
                                        newUse = [...categories].filter((v) => !v.startsWith(category.value));
                                        onChangeCategories("categories", newUse);

                                        if (selectedMajor === category.name) {
                                            setSelectedMajor("");
                                        }
                                    } else {
                                        newUse = [...categories, ...category.inputValue];
                                        if (getRealLength(newUse)) {
                                            showToast();
                                            return;
                                        }
                                        onChangeCategories("categories", newUse);
                                        setSelectedMajor(category.name);
                                    }
                                    newUse.length && majorRef.current.scrollTo(0, 0);
                                }}
                            >
                                {category.name}
                            </button>
                        );
                    })}
                </div>
                {categories.length > 0 && (
                    <div css={selectBoxStyle(MAX_Y)}>
                        {useFilterList[selectedMajor]?.map((category: any, i: number) => {
                            const checkMajor = category.value.slice(0, 1);
                            const checkActiveList = categories.filter((val: any) => val.includes(checkMajor));
                            const activeListLengthCheck = useFilterList[selectedMajor].length - 1 === checkActiveList.length;
                            let checkActive: boolean = true;
                            activeListLengthCheck
                                ? i
                                    ? (checkActive = false)
                                    : (checkActive = true)
                                : i
                                ? (checkActive = categories.some((val: any) => val.includes(category.value)))
                                : (checkActive = false);
                            return (
                                (selectedMajor !== "기타" || i === 0) && (
                                    <button
                                        key={`${category.name}${category.value}`}
                                        css={selectButtonStyle(checkActive)}
                                        onClick={() => {
                                            const checkInclude =
                                                !!i && categories.some((val: any) => val.includes(category.value));
                                            if (activeListLengthCheck && i === 0) {
                                                const newUse = [...categories].filter((v) => !v.includes(checkMajor));
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                onChangeCategories("categories", newUse);
                                            } else if (i === 0 || (activeListLengthCheck && checkInclude)) {
                                                const newUse = [...categories].filter((v) => !v.includes(checkMajor));
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                const addUse = i
                                                    ? [category.value]
                                                    : useFilterList[selectedMajor]?.slice(1).map((v: any) => v.value);
                                                onChangeCategories("categories", [...newUse, ...addUse]);
                                            } else if (checkInclude) {
                                                const newUse = [...categories].filter((v) => !v.includes(category.value));
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                onChangeCategories("categories", newUse);
                                            } else {
                                                const newUse = [...categories, category.value];
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                onChangeCategories("categories", newUse);
                                            }
                                        }}
                                    >
                                        {category.name}
                                    </button>
                                )
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AiRecommendThirdStep;

const rootBoxStyle = css`
    background-color: #ffffff;
    font-size: 14px;
    padding: 0px 14px;
`;

const titleBoxStyle = css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const subTitleBoxStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 0.6px solid #b1aeb8;
    .subTitleText {
        padding: 8px 14px;
        background-color: #ffffff;
        color: #0c002c;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        box-sizing: border-box;
        width: 100%;
    }
`;

const selectBoxRootStyle = css`
    display: flex;
    align-items: center;
`;

const selectBoxStyle = (MAXY: number) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    padding: 12px 10px;
    gap: 8px;
    height: ${MAXY - 300}px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    background: #fdfafa;
    transition: color 0.3s linear;
    font-size: 14px;
    font-weight: 500;
    ${active && {
        background: Common.colors.aucMarkerColor,
        color: "#ffffff",
    }}
`;

const summaryBoxStyle = css`
    padding: 8px 14px;
    display: flex;
    align-items: center;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;
