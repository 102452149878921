import { css } from "@emotion/react";
import ArrowBackIosNewRounded from "@mui/icons-material/ArrowBackIosNewRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { ClickAwayListener } from "@mui/material";
import {
    getInquiryItemDetail,
    useEditInquiryDetail,
    useSaverInquiryContent,
    useSaverInquiryImg,
} from "@src/hooks/customerService/inquiry";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DelBtn from "@src/assets/customerService/greyDel.png";
import MgDialog from "@src/components/Dialog";

type inquiryFormType = {
    category?: string;
    contents?: string;
    device?: string;
    imgUrl?: {
        name?: string;
        path?: string;
    }[];
};

const TABLIST = ["문의 유형 선택", "서비스 이용", "미스고 구독", "전문가 회원", "전문가 상담 / 입찰대행", "결제 / 환불 / 정산"];

const initialState: inquiryFormType = {
    category: "",
    contents: "",
    device: "pc",
};

const CustomerServiceInquiry = () => {
    const onPrevHandler = () => {
        transferToNative("done", "closeWebView");
    };

    const [userFileAuth, setUserFileAuth] = useState(false);

    WebAppBridge.setGrantedPhotoLibrary = (check: boolean) => {
        console.log("response: ", check);
        setUserFileAuth(check);
    };
    useEffect(() => {
        console.log("auth: ", userFileAuth);
    }, [userFileAuth]);

    const [searchParams] = useSearchParams();

    const [selectedType, setSelectedType] = useState<number>(0);
    const [openDialog, setOpenDialog] = useState<number>(0);
    const [dialogContent, setDialogContent] = useState<any>();
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const [inquiryParams, setInquiryParams] = useState<inquiryFormType>(initialState);
    const [files, setFiles] = useState<any[]>([]);

    //* 게시글 수정
    const { data, refetch, isFetching } = getInquiryItemDetail(Number(searchParams.get("inquiryChatNo") ?? 0)); //*문의사항 상세보기

    const [showImages, setShowImages] = useState<any[]>([]);

    // 이미지 상대경로 저장
    const handleAddImages = (event: any) => {
        const imageLists = event.target.files;
        let imageUrlLists: any = [...showImages];

        for (let i = 0; i < imageLists.length; i++) {
            const currentImageUrl: any = URL.createObjectURL(imageLists[i]);
            imageUrlLists.push(currentImageUrl);
        }

        if (imageUrlLists.length > 10) {
            imageUrlLists = imageUrlLists.slice(0, 10);
        }

        setShowImages(imageUrlLists);
    };

    useEffect(() => {
        if (searchParams.get("inquiryChatNo") !== null) {
            refetch();
        }
    }, [searchParams.get("inquiryChatNo")]);

    // X버튼 클릭 시 이미지 삭제
    const handleDeleteImage = (id: any) => {
        setShowImages(showImages.filter((_, index) => index !== id));
    };

    const onClickOpenDialog = (type: number) => {
        setOpenDialog(type);
        makeDialogContent(type);
    };

    const inquiryImage = useSaverInquiryImg(
        (res) => {
            const newName = decodeURI(res?.data?.[0]?.name);
            setInquiryParams((prev: inquiryFormType) => ({
                ...prev,
                imgUrl: [
                    ...(prev.imgUrl || []),
                    {
                        name: newName,
                        path: res?.data?.[0]?.path,
                    },
                ],
            }));
        },
        (err) => {
            const error: any = err.response?.data;
            onClickOpenDialog(5);
            setDialogContent(<p css={dialogContentStyle}>{error?.message}</p>);
        },
    );
    const onChangeImg = async (e: any) => {
        const image = e.target.files;
        const imgUrlList: any = [...(inquiryParams.imgUrl ?? [{ name: "", path: "" }])];
        let file = image[0] as any;
        let newName = encodeURI(file?.name);
        imgUrlList.push(file);
        let formData = new FormData();
        formData.append("file", new File([file], newName, { type: file.type }));
        inquiryImage.mutate(formData as any);
    };

    const saverInquiry = useSaverInquiryContent(
        () => {
            onClickOpenDialog(4);
        },
        (err) => {
            const error: any = err.response?.data;
            setDialogContent(<p css={dialogContentStyle}>{error?.message}</p>);
        },
    );

    const editInquiryDetail = useEditInquiryDetail(
        () => {
            setOpenDialog(0);
        },
        (err) => {
            const error: any = err.response?.data;
            onClickOpenDialog(5);
            setDialogContent(<p css={dialogContentStyle}>{error?.message}</p>);
        },
    );

    //* 모달 핸들러
    const makeDialogContent = (type: number) => {
        let newVal: string;
        switch (type) {
            case 1:
                newVal = "업로드는 최대 3개까지만 가능합니다";
                break;
            case 2:
                newVal = "업로드는 최대 10MB까지만 가능합니다";
                break;
            case 3:
                newVal = "제목은 최소 2자 이상 입력해 주세요";
                break;
            case 4:
                newVal = "문의가 등록되었습니다\n 문의 확인 후 빠른 답변 도와 드릴게요";
                break;
            case 6:
                newVal = "문의가 수정되었습니다.";
                break;
            default:
                newVal = "";
        }
        return setDialogContent(<p css={dialogContentStyle}>{newVal}</p>);
    };

    useEffect(() => {
        if (data && searchParams.get("inquiryChatNo") !== null) {
            setInquiryParams({
                ...inquiryParams,
                category: data?.category,
                contents: data?.contents,
                imgUrl: data?.imgUrl,
            });
            setFiles(data?.imgUrl ?? []);
            const imageUrlList: any = [];
            data?.imgUrl?.map((ele) => {
                imageUrlList.push(ele.path);
            });
            setShowImages(imageUrlList);
        }
    }, [data]);

    return (
        <div css={rootBoxStyle}>
            <div className="headerBox">
                <div onClick={onPrevHandler}>
                    <ArrowBackIosNewRounded className="backIcon" />
                </div>
                <p>{searchParams?.get("inquiryChatNo") !== null ? "문의하기 내용 수정" : "1:1 개선 문의"}</p>
            </div>
            <div className="inputBox">
                <p>
                    문의 유형 <span>*</span>
                </p>
                <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
                    <div css={selectedRootStyle(openSelect)}>
                        <div css={selectedBoxStyle} onClick={() => setOpenSelect((pre) => !pre)}>
                            <p className="name">
                                {searchParams.get("inquiryChatNo") !== null ? data?.category : TABLIST[selectedType]}
                            </p>
                            <KeyboardArrowDownRoundedIcon />
                        </div>
                        <div css={selectBoxStyle(openSelect)}>
                            {TABLIST.map((tab, idx) => (
                                <div
                                    key={tab}
                                    css={selectTitleStyle}
                                    className={selectedType === idx ? "active" : ""}
                                    onClick={() => {
                                        setSelectedType(idx);
                                        setOpenSelect(false);
                                        setInquiryParams({
                                            ...inquiryParams,
                                            category: tab,
                                        });
                                    }}
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
            <div className="textAreaBox">
                <p>
                    문의 내용 <span>*</span>
                </p>
                <div css={inputBoxStyle}>
                    <textarea
                        css={contentTextareaStyle}
                        placeholder="문의 내용을 입력해 주세요(1000자 이내)"
                        value={inquiryParams?.contents?.replace(/<br\s*\/?>/gm, "\n")}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = inputValue.replace(/\n/g, "\n");
                            setInquiryParams({
                                ...inquiryParams,
                                contents: sanitizedValue,
                            });
                        }}
                        maxLength={1000}
                    />
                    <p css={countContentStyle}>{inquiryParams?.contents?.length}/1000</p>
                </div>
            </div>
            <div className="imgBox">
                <p>첨부 파일</p>
                <div css={fileSelectBox(!userFileAuth || showImages.length > 2)}>
                    <div
                        onClick={() => {
                            transferToNative("", "onClickUserFileInput");
                            document.getElementById("img-inquiry")?.click();
                            if (showImages?.length > 2) {
                                onClickOpenDialog(1);
                            }
                        }}
                        className="fileBtn"
                    >
                        <p>파일 업로드</p>
                    </div>
                    <input
                        onClick={() => {
                            console.log("input clicked");
                        }}
                        id="img-inquiry"
                        accept="image/*"
                        disabled={showImages?.length > 2 || !userFileAuth}
                        onChange={(e: any) => {
                            handleAddImages(e);
                            onChangeImg(e);
                        }}
                        type="file"
                    />
                </div>
            </div>
            <div className="previewBox">
                {showImages?.map((file, idx) => (
                    <div className="imgContainer" key={idx.toString()}>
                        <img src={file} alt={`${file}-${idx}`} />
                        <div className="delBtn" onClick={() => handleDeleteImage(idx)}>
                            <img src={DelBtn} width={20} alt="delete" />
                        </div>
                    </div>
                ))}
            </div>
            <p css={addFileDescStyle}>* 이미지 파일만 업로드 가능하며 파일 당 10MB 이하, 최대 3개까지 등록 가능합니다.</p>
            <div css={submitBtnBox}>
                <button
                    onClick={() => {
                        if (searchParams?.get("inquiryChatNo") !== null) {
                            onClickOpenDialog(6);
                        } else {
                            saverInquiry.mutate(inquiryParams as any);
                        }
                    }}
                    disabled={
                        !(
                            inquiryParams?.contents &&
                            inquiryParams?.category !== "문의 유형 선택" &&
                            inquiryParams?.category !== ""
                        )
                    }
                >
                    {searchParams.get("inquiryChatNo") !== null ? "수정" : "개선 문의하기"}
                </button>
            </div>
            <MgDialog
                open={!!openDialog}
                onCloseView={() => {
                    if (openDialog === 4) {
                        setOpenDialog(0);
                        transferToNative("done", "closeWebView");
                    } else if (openDialog === 6) {
                        editInquiryDetail.mutate({
                            inquiryChatNo: Number(searchParams.get("inquiryChatNo")),
                            inquiryParams: inquiryParams,
                        });
                        transferToNative("done", "closeWebView");
                    } else {
                        setOpenDialog(0);
                    }
                }}
                width="284px"
                confirmStyle={{ fontSize: "18px" }}
            >
                {dialogContent}
            </MgDialog>
        </div>
    );
};

export default CustomerServiceInquiry;

const rootBoxStyle = css`
    width: 100vw;

    .headerBox {
        width: 100vw;
        position: sticky;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        z-index: 2;
        top: 0;
        background-color: white;
        padding: 14px;

        & > p {
            font-size: 18px;
            line-height: 28px;
            font-weight: bold;
        }

        .backIcon {
            position: absolute;
            font-size: 20px;
            left: 14px;
            top: 18px;
        }
    }

    .inputBox {
        padding: 14px;
        display: flex;
        align-items: center;
        gap: 14px;
        width: 100%;
        & > p {
            font-size: 16px;
            line-height: 22px;
            white-space: nowrap;
            & > span {
                color: #ff0000;
            }
        }
    }
    .imgBox {
        padding: 14px;
        display: flex;
        align-items: center;
        gap: 14px;
        width: 100%;
        & > p {
            font-size: 16px;
            line-height: 22px;
            white-space: nowrap;
        }
    }

    .textAreaBox {
        padding: 14px;
        display: flex;
        align-items: flex-start;
        gap: 14px;
        width: 100%;
        & > p {
            font-size: 16px;
            line-height: 22px;
            white-space: nowrap;
            padding-top: 8px;
            & > span {
                color: #ff0000;
            }
        }
    }

    .previewBox {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px;
        gap: 8px;

        .imgContainer {
            display: flex;
            position: relative;
            & > img {
                width: 100px;
                height: 100px;
                border-radius: 8px;
            }

            .delBtn {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }
`;

const selectedRootStyle = (props: boolean) => css`
    position: relative;
    cursor: pointer;
    max-width: 954px;
    background-color: #f6faff;
    border: ${props ? `1px solid ${Common.colors.aucMarkerColor}` : "1px solid #f6faff"};
    border-radius: 14px;
    box-sizing: border-box;

    padding: 10px 18px;
    width: 100%;
`;

const selectedBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
        color: ${Common.colors.darkGrey04};
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
`;

const selectBoxStyle = (props: boolean) => css`
    visibility: ${props ? "visible" : "hidden"};
    opacity: ${props ? 1 : 0};
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    border-radius: 14px;
    border: 0.6px solid #dadada;
    background: #ffffff;
    width: 100%;
    max-width: 950px;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 1;
    transition: all 0.2s;
`;

const selectTitleStyle = css`
    color: #8d8d8d;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 18px;
    :hover {
        background-color: ${Common.colors.aucMarkerColor} !important;
        color: #ffffff;
    }
    :first-of-type {
        border-radius: 14px 14px 0 0;
    }
    :last-of-type {
        border-radius: 0 0 14px 14px;
    }
    &.active {
        background-color: #f6faff;
    }
`;

const inputBoxStyle = css`
    position: relative;
    max-width: 954px;
    width: 100%;
`;

const contentTextareaStyle = css`
    font-weight: 400;
    width: 100%;
    max-width: 954px;
    height: 250px;
    resize: none;
    outline: none;
    border-radius: 14px;
    background-color: #f6faff;
    font-size: 14px;
    line-height: 20px;
    padding: 14px;
    border: 1px solid #f6faff;
    box-sizing: border-box;
    :placeholder {
        color: ${Common.colors.darkGrey04};
    }
    &:focus {
        border: 1px solid ${Common.colors.aucMarkerColor};
    }
`;

const countContentStyle = css`
    color: ${Common.colors.grey04};
    font-feature-settings: "clig" off, "liga" off;
    font-weight: 500;
    position: absolute;
    right: 0;
    transform: translate(-50%, -50%);

    font-size: 12px;
    line-height: 18px;
    bottom: 0px;
`;

const fileSelectBox = (disabled: boolean) => css`
    position: relative;
    .fileBtn {
        width: 150px;
        height: 50px;
        background-color: white;
        border: 1px solid #2275ee;
        color: #2275ee;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px 24px;
        position: relative;
        z-index: 2;
        border-radius: 14px;
    }

    & > input {
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
        position: absolute;
        opacity: 0;
    }
`;

const addFileDescStyle = css`
    margin-top: 8px;
    padding: 0px 14px;
    color: ${Common.colors.darkGrey04};
    font-weight: 400;
    line-height: 24px;
    font-size: 12px;
`;

const submitBtnBox = css`
    width: 100%;
    padding: 14px;

    & > button {
        width: 100%;
        padding: 8px;
        font-size: 18px;
        line-height: 32px;
        background-color: #2275ee;
        border-radius: 14px;
        color: white;

        &:disabled {
            background-color: #c3c3c3;
        }
    }
`;

const dialogContentStyle = css`
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 24px;
    white-space: pre-wrap;
`;
