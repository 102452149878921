import React, { MouseEventHandler, RefObject, useEffect, useId, useRef, useState } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Item from "@src/components/Item/Item";
import { useMutation, useQuery } from "react-query";
import { GetNewAuctionResponse, NewAuctionItem, useGetNewAuctions } from "@src/hooks/item/useGetNewItems";
import Lottie from "lottie-react";
import loading from "@src/assets/Loading.json";
import Info from "@src/assets/information.svg";
import NotFoundImg from "@src/assets/noRecentList.png";
import MgDialog from "@src/components/Dialog";
import ico_remove_black from "@src/assets/ico_remove_black.svg";
import { transferToNative } from "@src/lib/WebAppBridge";

enum KindOfSelect {
    AUCTION = 1,
    PUBLIC_SALE,
}

function DailyNewAnnounce() {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectState, setSelectState] = useState<KindOfSelect>(KindOfSelect.AUCTION);

    const { newAuctions, newAuctionData, totalCnt, isLoading, getNextAuctions } = useGetNewAuctions({ initialPage: 1 });
    const filteredList = newAuctions.filter((list) => list.bid_type === selectState);

    const onClickAuctionButton: MouseEventHandler<HTMLButtonElement> = (e) => {
        setSelectState(KindOfSelect.AUCTION);
    };

    const onClickPublicAuctionButton: MouseEventHandler<HTMLButtonElement> = (e) => {
        setSelectState(KindOfSelect.PUBLIC_SALE);
    };

    const onClickInfoIcon: MouseEventHandler<HTMLImageElement> = (e) => {
        setModalOpen(true);
    };

    const onScroll = (kind: KindOfSelect) => (e: React.UIEvent<HTMLElement>) => {
        const scrollTop = e.currentTarget.scrollTop;
        const scrollHeight = e.currentTarget.scrollHeight;
        const clientHeight = e.currentTarget.clientHeight;

        if (scrollHeight - scrollTop - clientHeight <= 50 && newAuctionData?.list.length !== 0) {
            getNextAuctions();
        }
    };

    const onClickExitButton: MouseEventHandler<HTMLButtonElement> = () => {
        transferToNative("", "closeWebView");
    };

    const onClickItem = (item: NewAuctionItem) => {
        console.log("ddd");
        if (localStorage.getItem("isSubscription") === "false") {
            transferToNative("", "showSubscriptionPopup");
        } else {
            transferToNative(JSON.stringify(item), "onClickItem");
        }
    };

    return (
        <>
            <MgDialog
                open={modalOpen}
                onCloseView={() => setModalOpen(false)}
                width="100%"
                addDialogStyle={{ textAlign: "center" }}
            >
                <ModalTitle>오늘 공고된 신건</ModalTitle>
                <ModalContent>
                    오늘 날짜 기준으로 14일 후 진행하는 <br /> 물건을 공고하고 있습니다.
                </ModalContent>
                <ModalContent>
                    기간입찰인 경우 입찰기간 시작일 <br /> 14일 이전 물건이 검색됩니다.
                </ModalContent>
            </MgDialog>

            <Title>
                오늘 공고된 신건&nbsp;
                <InfoIcon onClick={onClickInfoIcon} />
            </Title>
            <ExitButton onClick={onClickExitButton}>
                <img src={ico_remove_black} />
            </ExitButton>

            <SwitchButtonSection>
                <SwitchButton
                    css={selectState === KindOfSelect.AUCTION ? ButtonStyle.selected : ButtonStyle.unselected}
                    onClick={onClickAuctionButton}
                >
                    경매
                </SwitchButton>
                <SwitchButton
                    css={selectState === KindOfSelect.PUBLIC_SALE ? ButtonStyle.selected : ButtonStyle.unselected}
                    onClick={onClickPublicAuctionButton}
                >
                    공매
                </SwitchButton>
            </SwitchButtonSection>
            <NavSection>
                <TotalItemsText>총매물 {totalCnt} 건</TotalItemsText>
            </NavSection>
            <ListItemSection onScrollCapture={(e) => onScroll(selectState)(e)}>
                {filteredList.length === 0 ? (
                    <NotFound>
                        <NotFoundImage srcSet={NotFoundImg} />
                        <NotFoundText>오늘 공고된 신건이 없어요.</NotFoundText>
                    </NotFound>
                ) : (
                    newAuctions.map((newAuction, idx) => {
                        return (
                            <Item
                                next_it={""}
                                key={newAuction.item_id + idx}
                                {...newAuction}
                                onClick={() => onClickItem(newAuction)}
                            ></Item>
                        );
                    })
                )}
                {isLoading && filteredList.length !== 0 && (
                    <StyledLottie>
                        <Lottie className="lottie" animationData={loading} />
                    </StyledLottie>
                )}
            </ListItemSection>
        </>
    );
}

const StyledLottie = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    scale: 0.5;
    & > .lottie {
        width: 50%;
    }
`;

const InfoIcon = ({ onClick }: { onClick: MouseEventHandler<HTMLImageElement> }) => {
    return <img src={Info} onClick={onClick} />;
};

const ButtonStyle = {
    selected: css`
        background-color: #2275ee;
        color: #ffffff;
    `,
    unselected: css`
        background-color: #fbf8f8;
        color: #8d8d8d;
        font-weight: 500;
    `,
};

const Title = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    margin: 14px;
`;

const ExitButton = styled.button`
    position: absolute;
    top: 14px;
    right: 14px;
`;

const SwitchButtonSection = styled.section`
    display: flex;
    padding: 14px;
    height: 80px;
    gap: 8px;
`;

const SwitchButton = styled.button`
    flex: 1;
    border-radius: 8px;
    font-weight: bold;
    transition: background-color 0.15s ease;
    will-change: background-color font-weight color;
    font-size: 16px;
`;

const NavSection = styled.nav`
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    padding: 0 14px 0 14px;
`;

const TotalItemsText = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
`;

const ListItemSection = styled.section`
    /**
     *  174px = 28px(title) + 94px(button section, height + padding top bottom) + 38px(nav height, height + margin bottom) + 14px(Item Component padding)
     *  @notice Item 컴포넌트 내부 패딩에 따라서 이 값은 변경 될 수 있음.
     */
    max-height: calc(100dvh - 174px);
    overflow-y: scroll;
`;

const NotFoundImage = styled.img`
    scale: 0.5;
`;

const NotFound = styled.div`
    position: absolute;
    /* right: calc(50vw - 55px); */
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NotFoundText = styled.h1`
    font-size: 12px;
    font-weight: 400;
    line-height: 25.5px;
    text-align: center;
`;

const ModalTitle = styled.h1`
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;
const ModalContent = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;

export default DailyNewAnnounce;
