const AUCTION_MASTER_ITEMS = {
    전체: 0,
    유치권: 1,
    분묘기지권: 2,
    법정지상권: 3,
    위반건축물: 4,
    농취증필요: 5,
    토지만입찰: 6,
    건물만입찰: 7,
    토지별도등기: 8,
    지분매각: 9,
    대지권미등기: 10,
    'HUG 임차권 인수조건 변경': 11,
    가등기: 12,
    가처분: 13,
}; 

export type AuctionMasterItemType = typeof AUCTION_MASTER_ITEMS;
export type AuctionMasterItemKey = keyof AuctionMasterItemType;
export type AuctionMasterItemValue = AuctionMasterItemType[AuctionMasterItemKey];



export const getGoStopAuctionMasterItems = () => {
    //고스톱 경매고수물건 필터의 경우 '지분매각:9' 제외
    const { 지분매각: _, ...rest } = AUCTION_MASTER_ITEMS;

    return rest;
};

export const getGoStopAuctionMasterItemsReversed = ()=>{
  return Object.entries(getGoStopAuctionMasterItems()).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: key as Omit<AuctionMasterItemKey,'지분매각'>
    }),
    {} as Record<number, Omit<AuctionMasterItemKey,'지분매각'>>
  );
}

export const getAuctionMasterItemsReversed = ()=>{
   return Object.entries(AUCTION_MASTER_ITEMS).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [value]: key as AuctionMasterItemKey
        }),
        {} as Record<number, AuctionMasterItemKey>
      );
}

export default AUCTION_MASTER_ITEMS