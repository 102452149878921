import React from "react";
import { css } from "@emotion/react";
import IcoClose from "@src/assets/ico_close.png";
import EventPageBanner from "../../../assets/event/appAiDividendTableEvent.png";
import { useNavigate } from "react-router-dom";
import { getDeviceType, transferToNative } from "@src/lib/WebAppBridge";

const AiDividendEvent = () => {
    const navigate = useNavigate();

    const onClickClose = () => {
        transferToNative("done", "closeWebView");
    };

    return (
        <>
            <div css={rootBoxStyle}>
                <div css={titleBoxStyle}>
                    <div className="title">AI 예상배당표 신기능 출시</div>
                    <button className="close-icon" onClick={onClickClose}>
                        <img src={IcoClose} alt="close" />
                    </button>
                </div>
                <button
                    onClick={() => {
                        getDeviceType() === "android"
                            ? navigate("/subscriptionInfo?type=6")
                            : getDeviceType() === "ios"
                            ? navigate("/ios-subscriptionInfo?type=7")
                            : "";
                    }}
                >
                    구독하러가기
                </button>
                <img src={EventPageBanner} alt="AiDividendEvent 페이지" />
            </div>
        </>
    );
};

export default AiDividendEvent;

const rootBoxStyle = css`
    width: 100%;
    height: 100vh;
    overflow: hidden auto;
    position: relative;

    & > img {
        width: 100%;
    }

    & > button {
        position: fixed;
        z-index: 100;
        font-size: 26px;
        padding: 10px 48px;
        border-radius: 100px;
        bottom: 80px;
        transform: translateX(-50%);
        white-space: nowrap;
        left: 50%;
        background: rgb(0, 71, 255);
        box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
        color: #ffffff;
    }
`;

const titleBoxStyle = css`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    padding: 14px;

    & .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;
    }

    & .close-icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        height: 14px;

        img {
            width: 14px;
            height: 14px;
        }
    }
`;
