import create from "zustand";

type PositionType = {
    lat: string;
    lng: string;
};

type PositionStoreType = {
    position: PositionType;
    setPosition(pos: PositionType): PositionStoreType;
};

export const usePositionStore = create<PositionStoreType>((set) => {
    return {
        position: {
            lat: "0",
            lng: "0",
        },
        setPosition: (pos: PositionType) => {
            set(() => {
                return {
                    position: {
                        ...pos,
                    },
                };
            });
        },
    } as PositionStoreType;
});
