import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

export type NewAuctionItem = {
    card_type: number;
    item_id: string;
    case_number: string;
    bid_type: number;
    bid_type_str: string;
    bid_type_detail: number;
    use: string;
    skp_cnt: number;
    skp_cnt_str: string;
    image: string;
    address: string;
    est_price: number;
    est_price_str: string;
    min_price: number;
    min_price_str: string;
    rate: string;
    status: string;
    status_detail: string;
    d_day: string;
    due_date_str: string;
    due_date: number;
    somethings: string[];
    updated_at: string;
    is_favorited: boolean;
    start_dcs_date: string;
    distrib_req_duedate: string;
    date_info: string;
    mid_adrs: string;
    claim_amount_str: string;
    sale_price: number;
    sale_price_str: string;
    sale_price_rate: string;
    view_count: number;
    area_info: string;
    naver_url: string;
    lat: number;
    lng: number;
    square: number;
};

export type NewAuctionData = {
    list: NewAuctionItem[];
    totalCnt: number;
};

export type GetNewAuctionResponse = NewAuctionData & {};

export type FetchDataParams = {
    page: number;
};

export const getNewAuctions = async (params: FetchDataParams = { page: 1 }): Promise<GetNewAuctionResponse> => {
    const res = await axiosInstance.get<GetNewAuctionResponse>(`${axiosPath.itemThemeNew}`, {
        params: {
            page: params.page,
        },
    });

    if (res.status !== 200) {
        throw new Error("server error!");
    }

    return res.data;
};

const queryKey = "getNewAuctions";

export const useGetNewAuctions = ({ initialPage }: { initialPage: number }) => {
    const [page, setPage] = useState<number>(1);
    const [totalCnt, setTotalCnt] = useState<number>();
    const [newAuctions, setNewAuctions] = useState<NewAuctionItem[]>([]);

    const {
        mutateAsync,
        isLoading,
        data: newAuctionData,
    } = useMutation(({ page }: { page: number }) => getNewAuctions({ page }));

    useEffect(() => {
        mutateAsync({ page: 1 }).then((data) => {
            setNewAuctions(data.list);
            setTotalCnt(data.totalCnt);
        });
    }, []);

    const getNextAuctions = async () => {
        setPage((prev) => prev + 1);
        const data = await mutateAsync({ page });
        setNewAuctions((prev) => [...prev, ...data.list]);
    };

    return {
        newAuctions,
        newAuctionData,
        isLoading,
        getNextAuctions,
        totalCnt,
    };
};
