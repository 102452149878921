import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { IFilterParamsType } from ".";
import ShowSelectedUseLocValueBox from "./ShowSelectedUseLocValueBox";
import { landUseCategoryListLength, useFilterTextList } from "./useFilterList";
import ShowSelectedValueBox from "./ShowSelectedValueBox";
import { findKeyByValueFromStatus } from "./AuctionStatusFilter";
import { assetList, saleMethodList, typeList } from "./AuctionTypeFilter";
import { auctionSkipList } from "./AuctionSkipFilter";
import NewFilterHistoryLoc from "./NewFilterHistoryLoc";
import { useNewFilterStore } from "@src/store/newFilterStore";
import { useGetLocation } from "@src/hooks/newFilter/useGetLocation";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import { auctionCounterForceList } from "./AuctionCounterForceFilter";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { getAuctionMasterItemsReversed } from "@src/constants/auctionMasterItemFilters";

interface Props {
    filterParams: IFilterParamsType;
    from?: string;
}

const SelectedFilterList: React.FC<Props> = ({ filterParams, from = "all" }) => {
    const setFilterParams = useNewFilterStore((state) => state.setStoreNewFilterState);
    const [showLocText, setShowLocText] = useState<any[]>([]);
    const makeLocText = async (loc: string) => {
        let sidoText = "";
        let sigunguText = "";
        let emdText = "";
        sidoText = await useGetLocation("0000000000").then((res) => {
            const selectedSido = res.filter((val: any) => val?.cortarNo?.slice(0, 2) === loc.slice(0, 2));
            return selectedSido?.[0]?.cortarName ?? "";
        });
        if (loc.slice(2) !== "00000000") {
            sigunguText = await useGetLocation(`${loc.slice(0, 2)}00000000`).then((res) => {
                const selectedSigungu = res.filter((val: any) => val?.cortarNo?.slice(0, 5) === loc.slice(0, 5));
                return selectedSigungu?.[0]?.cortarName;
            });
        } else {
            return { name: `${sidoText} > 전체`, value: loc };
        }
        if (loc.slice(5) !== "00000") {
            emdText = await useGetLocation(`${loc.slice(0, 5)}00000`).then((res) => {
                const selectedEmd = res.filter((val: any) => val?.cortarNo === loc);
                return selectedEmd?.[0]?.cortarName;
            });
        } else {
            emdText = "전체";
        }
        return { name: `${sidoText} > ${sigunguText} > ${emdText}`, value: loc };
    };
    const settingLocText = async () => {
        const result = await Promise.all(
            filterParams?.loc?.map(async (val) => {
                return await makeLocText(val);
            }),
        );
        setShowLocText(result);
    };
    useEffect(() => {
        if (filterParams?.loc?.length) {
            settingLocText();
        } else {
            setShowLocText([]);
        }
    }, [filterParams?.loc]);

    return (
        <>
            {!!filterParams.loc.length && (
                <div css={summaryBoxStyle}>
                    {from === "history" && (
                        <NewFilterHistoryLoc
                            location={filterParams.loc.map((val) => (val.includes("All") ? val.slice(0, -3) : val))}
                        />
                    )}
                    {from === "all" &&
                        !!showLocText.length &&
                        showLocText.map((locData: any) => (
                            <ShowSelectedUseLocValueBox
                                value={locData.name}
                                onDeleteValue={() => {
                                    const newLoc = [...filterParams.loc].filter((val) => val !== locData.value);
                                    setFilterParams({ ...filterParams, loc: [...newLoc] });
                                }}
                                key={locData.name}
                            />
                        ))}
                </div>
            )}
            {!!filterParams.use.length && (
                <div css={summaryBoxStyle}>
                    {!!filterParams.use.length &&
                        Array.from(
                            new Set(
                                makeUseParam(filterParams.use).map((v) => {
                                    const checkMajor = v.slice(0, 1);
                                    const checkActiveList = makeUseParam(filterParams.use).filter((val) =>
                                        val.includes(checkMajor),
                                    );
                                    const activeListLengthCheck =
                                        checkMajor === "A"
                                            ? checkActiveList.length === landUseCategoryListLength["A"]
                                            : checkMajor === "B"
                                            ? checkActiveList.length === landUseCategoryListLength["B"]
                                            : checkMajor === "C"
                                            ? checkActiveList.length === landUseCategoryListLength["C"]
                                            : checkMajor === "D"
                                            ? checkActiveList.length === landUseCategoryListLength["D"]
                                            : checkMajor === "E" && checkActiveList.length === 1;
                                    return activeListLengthCheck ? checkMajor : v;
                                }),
                            ),
                        ).map((val) => {
                            const newText = useFilterTextList.filter((text) => text.value === val);
                            return (
                                <ShowSelectedUseLocValueBox
                                    value={newText?.[0]?.name}
                                    onDeleteValue={
                                        from === "history"
                                            ? undefined
                                            : () => {
                                                  const newUse = [...filterParams.use].filter(
                                                      (val) => !val.includes(newText?.[0]?.value),
                                                  );
                                                  setFilterParams({ ...filterParams, use: [...newUse] });
                                              }
                                    }
                                    key={val}
                                />
                            );
                        })}
                </div>
            )}
            <div css={summaryBoxStyle}>
                {filterParams.type != 0 && from === "history" && (
                    <ShowSelectedValueBox value={typeList[filterParams.type].replaceAll("전체", "")} onDeleteValue={undefined} />
                )}
                {!!filterParams.status.length && filterParams.status[0] != 0 && (
                    <ShowSelectedValueBox
                        value={
                            filterParams.status.filter((status) => status !== 2).length > 1
                                ? `${findKeyByValueFromStatus(filterParams.status[0])} 외 ${
                                      filterParams.status.filter((status) => status !== 2).length - 1
                                  }`
                                : `${findKeyByValueFromStatus(filterParams.status[0])}`
                        }
                        onDeleteValue={from === "history" ? undefined : () => setFilterParams({ ...filterParams, status: [] })}
                    />
                )}
                {!!filterParams.auction_master_item.length && (
                    <ShowSelectedValueBox
                        value={
                            filterParams.auction_master_item.length > 1
                                ? `${getAuctionMasterItemsReversed()[filterParams.auction_master_item[0]]} 외 ${
                                      filterParams.auction_master_item.length - 1
                                  }${filterParams.auction_master_except ? " 제외" : ""}`
                                : `${getAuctionMasterItemsReversed()[filterParams.auction_master_item[0]].replaceAll(
                                      "전체",
                                      "",
                                  )}${filterParams.auction_master_except ? " 제외" : ""}`
                        }
                        onDeleteValue={
                            from === "history" ? undefined : () => setFilterParams({ ...filterParams, auction_master_item: [] })
                        }
                    />
                )}
                {filterParams.sales_method != 0 && (
                    <ShowSelectedValueBox
                        value={`${saleMethodList[filterParams.sales_method]}`}
                        onDeleteValue={
                            from === "history" ? undefined : () => setFilterParams({ ...filterParams, sales_method: 0 })
                        }
                    />
                )}
                {filterParams.skp_type != 0 && (
                    <ShowSelectedValueBox
                        value={
                            `${from === "history" && Number(filterParams.skp_type) === 2 ? "유찰횟수" : ""}` +
                            `${auctionSkipList[filterParams.skp_type].replaceAll("전체", "")}` +
                            `${Number(filterParams.skp_type) === 2 ? ` ${filterParams.skp_cnt[0]}` : ""}` +
                            `${
                                filterParams.skp_cnt.some((val) => val) && filterParams.skp_cnt[0] !== filterParams.skp_cnt[1]
                                    ? " ~"
                                    : ""
                            }` +
                            `${
                                Number(filterParams.skp_type) === 2 && filterParams.skp_cnt[0] !== filterParams.skp_cnt[1]
                                    ? ` ${filterParams.skp_cnt[1]}`
                                    : ""
                            }`
                        }
                        onDeleteValue={
                            from === "history"
                                ? undefined
                                : () => setFilterParams({ ...filterParams, skp_type: 0, skp_cnt: ["", ""] })
                        }
                    />
                )}
                {filterParams.counter_force != 0 && (
                    <ShowSelectedValueBox
                        value={`${auctionCounterForceList[filterParams.counter_force].replaceAll("전체", "")}`}
                        onDeleteValue={
                            from === "history" ? undefined : () => setFilterParams({ ...filterParams, counter_force: 0 })
                        }
                    />
                )}
                {!!filterParams.assets.length && (
                    <ShowSelectedValueBox
                        value={
                            filterParams.assets.length > 1
                                ? `${assetList[filterParams.assets[0]]} 외 ${filterParams.assets.length - 1}`
                                : `${assetList[filterParams.assets[0]].replaceAll("전체", "")}`
                        }
                        onDeleteValue={from === "history" ? undefined : () => setFilterParams({ ...filterParams, assets: [] })}
                    />
                )}
                {!!filterParams.due_date.every((val) => val) && (
                    <ShowSelectedValueBox
                        value={
                            filterParams.due_date[0] !== filterParams.due_date[1]
                                ? `${from === "history" ? "매각기일 " : ""}${filterParams.due_date[0].slice(
                                      2,
                                  )} ~ ${filterParams.due_date[1].slice(2)}`
                                : `${from === "history" ? "매각기일 " : ""}${filterParams.due_date[0].slice(2)}`
                        }
                        onDeleteValue={
                            from === "history" ? undefined : () => setFilterParams({ ...filterParams, due_date: ["", ""] })
                        }
                    />
                )}
                {filterParams.est_price.some((val) => val) && (
                    <ShowSelectedValueBox
                        value={
                            `감정가 ${
                                itemForAppraisedPrice.filter(
                                    (val) =>
                                        val.value ===
                                        (filterParams.est_price[0] === "30000000000" ? "higher" : filterParams.est_price[0]),
                                )?.[0]?.showText
                            } ~ ` +
                            `${
                                filterParams.est_price[1] &&
                                itemForAppraisedPrice.filter((val) => val.value === filterParams.est_price[1])?.[0]?.title
                            }`
                        }
                        onDeleteValue={
                            from === "history" ? undefined : () => setFilterParams({ ...filterParams, est_price: ["", ""] })
                        }
                    />
                )}
                {filterParams.min_price.some((val) => val) && (
                    <ShowSelectedValueBox
                        value={
                            `최저입찰가 ${
                                itemForAppraisedPrice.filter(
                                    (val) =>
                                        val.value ===
                                        (filterParams.min_price[0] === "30000000000" ? "higher" : filterParams.min_price[0]),
                                )?.[0]?.showText
                            } ~ ` +
                            `${
                                filterParams.min_price[1] &&
                                itemForAppraisedPrice.filter((val) => val.value === filterParams.min_price[1])?.[0]?.title
                            }`
                        }
                        onDeleteValue={
                            from === "history" ? undefined : () => setFilterParams({ ...filterParams, min_price: ["", ""] })
                        }
                    />
                )}
            </div>
        </>
    );
};

export default SelectedFilterList;

const summaryBoxStyle = css`
    background-color: #ffffff;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    width: 100vw;
    gap: 8px;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;
